import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import Section from "../Section";
import RichText from "../RichText";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

interface TeaserLargeMediaInterface {
  _id: string;
  _rawText: PortableText;
  image: ImageInterface;
  _rawImage: ImageInterface;
  theme?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  textAlignment?: string;
}

const TeaserLargeMedia: FunctionComponent<TeaserLargeMediaInterface> = props => {
  const { _id, _rawText, image, theme, textAlignment } = props;

  return (
    <>
      <Section sectionClassName={`teaser-largeMedia ${_id}`} theme={theme?.bgColor.title}>
        <div className={`teaser-largeMedia-image`}>
          <SanityImage
            {...image}
            sizes={IMAGE_SIZES.FULL_WIDTH}
            width={1400}
            height={843}
            htmlWidth={1400}
            htmlHeight={843}
            loading="eager"
          />
        </div>

        <div className={`teaser-largeMedia-content_wrapper align-${textAlignment}`}>
          <RichText data={_rawText} />
        </div>
      </Section>
    </>
  );
};

export default TeaserLargeMedia;
